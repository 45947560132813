import { DataAccess } from "../dataaccess/data.access";
import { Etags, EventService } from "./event.service";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { Timeout } from "../decorators/timeout.decorator";
import { EventDataMicroI, WsDataI } from "../modules/s25-event/EventMicroI";

export class EventMicroService {
    @Timeout
    @Cache({ immutable: true, targetName: "EventMicroService" })
    public static getEventDetailById(ids: number[], expand: string, include: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/micro/event/" + ids.join("+") + "/detail.json?expand=" + expand + "&include=" + include,
                "EventMicroService.getEventDetailById",
            ),
        );
    }

    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EventMicroService", methodName: "getEventDetailById", patternFunc: (args) => args[0] })
    public static async microPutEventDetail(data: EventDataMicroI, eventId: number) {
        const etags = await EventService.getEtags([eventId]);
        return DataAccess.put(
            DataAccess.injectCaller(
                "/micro/event/" + eventId + "/detail.json?expand=T&include=All",
                "EventMicroService.microPutEventDetail",
            ),
            {
                content: {
                    etags: etags,
                    data: data,
                },
            },
        );
    }

    // for reference https://src.unival.com/projects/database/browser/database/SERIES25/accelerator/s25ws-db/sample_event_reservation_json.json
    @Invalidate({ serviceName: "EvDetailService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdDefnService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdPrefService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EvdOccurService", methodName: "get", patternFunc: (args) => args[0] })
    @Invalidate({ serviceName: "EventMicroService", methodName: "getEventDetailById", patternFunc: (args) => args[0] })
    public static async putEventReservation(eventId: number, data: EventDataMicroI) {
        const etags = await EventService.getEtags([eventId]);
        return DataAccess.put(
            DataAccess.injectCaller(
                "/micro/event/" + eventId + "/reservation.json",
                "EventMicroService.putEventReservation",
            ),
            {
                content: {
                    apiVersion: "0.1",
                    etags: etags,
                    data: data,
                },
            },
        );
    }
}
